import { useEffect } from 'react';
import { loadPage } from '../../utils/route';
import { useGlobalStateRef } from './use-global-state';

/**
 * @deprecated
 *  NOTE: Removed for now, since it's not needed and causing issues with the page load
 * `usePreloadPage` will preload a page for faster navigation
 * @example
 *    usePreloadPage(<pageKey>);
 */

export function usePreloadPage(pageKeys: string | string[]) {}
