import { useCallbackRef } from '@vev/hooks';
import { RefObject, useCallback, useEffect } from 'react';
import { useContextModel } from '../context';

const modelRefs = new Map<string, React.RefObject<HTMLDivElement>>();

export function useRegisterHostRef(modelKey: string, hostRef: React.RefObject<HTMLDivElement>) {
  modelRefs.set(modelKey, hostRef);
  useEffect(() => {
    return () => {
      modelRefs.delete(modelKey);
    };
  }, [modelKey]);
}

export const useVevEvent = <T>(
  eventType: string,
  handler: (payload: T) => void,
  hostRef?: RefObject<HTMLDivElement>,
) => {
  const model = useContextModel();
  const cbRef = useCallbackRef(handler);

  const contentKey = model?.virtualKey || model?.key;

  const contextHostRef = hostRef || modelRefs.get(contentKey || '');
  useEffect(() => {
    const el = contextHostRef?.current;
    if (el) {
      const cb = (e: Event) => {
        cbRef.current?.((e as CustomEvent).detail);
      };
      el.addEventListener(eventType, cb);
      return () => {
        el.removeEventListener(eventType, cb);
      };
    }
  }, [cbRef, eventType, contextHostRef]);
};

export const useDispatchVevEvent = () => {
  const model = useContextModel();
  const contentKey = model?.virtualKey || model?.key;
  return useCallback(
    (eventType: string, args?: any) => {
      if (!contentKey) return;

      const el = modelRefs.get(contentKey)?.current;
      if (el) {
        el.dispatchEvent(
          new CustomEvent(eventType, {
            detail: { ...args, key: contentKey },
          } as any),
        );
      }
    },
    [contentKey],
  );
};
