import { useGlobalStore } from './use-store';
import { Variable } from 'vev';
import { useCallback } from 'react';

export function useSetVariable() {
  const [rootEl, variables] = useGlobalStore((s) => [s.root, s.variables], []);
  return useCallback(
    (variableKey: string, change: Partial<Variable>) => {
      if (rootEl) {
        const variable = variables.find((variable) => {
          return variable.key === variableKey;
        });

        if (variable) {
          const det = { ...variable, ...change };
          rootEl.dispatchEvent(
            new CustomEvent('vev.variable.set', {
              detail: det,
            }),
          );
        }
      }
    },
    [rootEl, variables],
  );
}
