import { useGlobalStore } from './use-store';
import { useEffect, useState } from 'react';
import { Variable } from 'vev';

export function useVariable(variableKey: string) {
  const [initialVariables, rootEl] = useGlobalStore((s) => [s.variables, s.root], []);
  const [variable, setVariable] = useState<Partial<Variable> | null>(null);

  useEffect(() => {
    if (rootEl) {
      const cb = (e: CustomEvent<{ key: string; value: string }>) => {
        console.log(e.detail);
        const { key, value } = e.detail;
        if (key === variableKey) {
          setVariable({ ...variable, value });
        }
      };

      rootEl.addEventListener<any>('vev.variable.change', cb);

      return () => {
        rootEl.removeEventListener<any>('vev.variable.change', cb);
      };
    }
  }, [rootEl, variableKey, variable]);

  useEffect(() => {
    if (initialVariables) {
      const initialVariable = initialVariables.find((variable) => {
        return variable.key === variableKey;
      });
      if (initialVariable) {
        setVariable(initialVariable);
      }
    }
  }, [initialVariables, variableKey]);

  return variable;
}
