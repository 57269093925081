import system from '../system';

export default function polyfills(): Promise<any> {
  const polyfills: Promise<any>[] = [];
  // Polyfill for fetch
  if (!('fetch' in window)) {
    polyfills.push(
      system.fetch('https://cdn.jsdelivr.net/npm/whatwg-fetch@3.0.0/dist/fetch.umd.js'),
    );
  }
  // Polyfill for intersection observer
  if (!('IntersectionObserver' in window)) {
    polyfills.push(
      system.import('https://unpkg.com/intersection-observer@0.5.1/intersection-observer'),
    );
  }
  // Polyfill for resize observer
  if (!('ResizeObserver' in window)) {
    polyfills.push(
      system.import(
        'https://cdn.jsdelivr.net/npm/resize-observer-polyfill@1.5.1/dist/ResizeObserver.min.js',
      ),
    );
  }

  // Polyfill scroll timeline
  if (!('ScrollTimeline' in window)) {
    polyfills.push(
      system.import('https://cdn.vev.design/v/scrolling-timeline/scroll-timeline-lite.js'),
    );
  }
  return Promise.all(polyfills);
}
