import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { DeviceSettings } from 'vev';
import {
  getScrollHeight,
  getScrollTop,
  scrollEl,
  useGlobalStateRef,
  useGlobalStore,
  useInterval,
  useSize,
} from '../core';
import ViewManager from '../manager/view';
import { VariableManager } from '@vev/variables';

interface ViewProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

function findDevice(devices: DeviceSettings[], width: number): DeviceSettings {
  return (
    devices.find((device, i, arr) => width >= device.columnSize || i === arr.length - 1) ||
    devices[0]
  );
}

function View({ children, style }: ViewProps, ref: React.Ref<HTMLDivElement>) {
  const bodyRef = useRef<HTMLElement>(document.body);
  const [project, editor, settings, device, variables, models] = useGlobalStore((state) => [
    state.project,
    state.editor,
    state.settings,
    state.device,
    state.variables,
    state.models,
  ]);

  const [stateRef, dispatch] = useGlobalStateRef();

  const scrollHeightRef = useRef<number>(-1);
  if (scrollHeightRef.current === -1) scrollHeightRef.current = scrollEl.scrollHeight;
  const scrollTopRef = useRef<number>(-1);
  if (scrollTopRef.current === -1) scrollTopRef.current = scrollEl.scrollTop;

  const checkScrollHeight = () => {
    const scrollHeight = getScrollHeight();
    if (scrollHeight !== scrollHeightRef.current) {
      scrollHeightRef.current = scrollHeight;
      ViewManager.scrollHeight = scrollHeight;
      dispatch('update-viewport');
    }
  };

  useSize(bodyRef, checkScrollHeight);
  // Fallback checkScrollHeight in case where use size does not work
  useInterval(checkScrollHeight, 500);

  useEffect(() => {
    const handleResize = () => dispatch('update-viewport');
    const handleScroll = () => {
      const top = getScrollTop();
      dispatch('scrollTop', top);
    };

    handleResize();

    self.addEventListener('resize', handleResize, { passive: true });
    self.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      self.removeEventListener('resize', handleResize);
      self.removeEventListener('scroll', handleScroll);
    };
  }, [settings]);

  useEffect(() => {
    if (!project || editor?.disabled || editor?.preRender) return;
    let styleEL: HTMLStyleElement;

    function getStyleEl() {
      if (!styleEL) {
        styleEL = document.createElement('style');

        const rootNode = (ref as React.MutableRefObject<HTMLDivElement>).current?.getRootNode();
        if (rootNode instanceof ShadowRoot) {
          rootNode.appendChild(styleEL);
        } else {
          document.body.appendChild(styleEL);
        }
      }
      return styleEL;
    }

    let prevZoom: number;
    const observer = new ResizeObserver((entries: any) => {
      const { width } = entries[0] && entries[0].contentRect;

      const { settings, device: deviceMode, editor } = stateRef.current;
      if (editor?.disabled || editor?.preRender) return;

      const device = findDevice(settings.devices, width);
      if (device.mode !== deviceMode) dispatch('device', device.mode);
      const minColumnWidth = device.columnSize;
      // Is deprecated, but needs to exist for now
      const fullWidth = device.scaling;
      const gutter = device.gutter || 0;
      const size = minColumnWidth + gutter * 2;

      let zoom = Math.round((width / size) * 100) / 100;
      if (!fullWidth && zoom > 1) zoom = 1;
      if (prevZoom === zoom) return;

      /** Need to update deprecated ViewManager */
      ViewManager.zoom = prevZoom = zoom;
      dispatch('zoom', zoom);

      const textSizeAdjust = `${Math.round(zoom * 100)}%`;

      getStyleEl().innerText =
        `.p${project} .__p,.p${project} .__f{` +
        `-webkit-text-size-adjust: ${textSizeAdjust};` +
        `-ms-text-size-adjust: ${textSizeAdjust};` +
        `-moz-text-size-adjust: ${textSizeAdjust};` +
        `text-size-adjust: ${textSizeAdjust};` +
        (zoom !== 1 ? `zoom: ${zoom};` : '') +
        (zoom !== 1 ? `-moz-transform: scale(${zoom});` : '') +
        '}';
    });

    if ((ref as React.MutableRefObject<HTMLDivElement>).current) {
      observer.observe((ref as React.MutableRefObject<HTMLDivElement>).current);
    }

    return () => {
      observer.disconnect();
      styleEL.remove();
    };
  }, [project, editor?.disabled]);

  useLayoutEffect(() => {
    const classes = ['__vev', 'p' + project, device];

    if (ViewManager.isIOS) classes.push('ios');
    if (ViewManager.isAndroid) classes.push('android');
    if (ViewManager.isIE) classes.push('ie');
    if (ViewManager.isChrome) classes.push('chrome');
    if (ViewManager.isFirefox) classes.push('firefox');
    if (ViewManager.isOpera) classes.push('opera');
    // Edge tries to be safari
    if (ViewManager.isEdge) classes.push('edge');
    else if (ViewManager.isSafari) classes.push('safari');

    (ref as React.MutableRefObject<HTMLDivElement>).current?.setAttribute(
      'class',
      classes.join(' '),
    );
  }, [project, device]);

  return (
    <div className={'p' + project + ' __vev'} style={style} ref={ref}>
      {children}
    </div>
  );
}

export default React.forwardRef(View);
